<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { AgGridVue } from "ag-grid-vue3";
import { onBeforeMount, ref } from "vue";
import ProductLayout from "../../../layouts/ProductLayout.vue";
import useProducts from "../../../hooks/useProducts";
import ProductHeader from "../../../components/products/ProductHeader.vue";
const themeClass = "ag-theme-material";

import { setPageTitle } from "../../../utils/pageTitle";
import { useRoute } from "vue-router/auto";

import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "@/stores/themeStore";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { fetchProduct, fetchProductWarehouses } = useProducts();

const route = useRoute();
const product = ref(null);
const warehouses = ref(null);
const themeStore = useThemeStore();
let gridApi;

onBeforeMount(async () => {
  product.value = await fetchProduct(route.params.id);
  setPageTitle(`Product -${product.value.id}`);
});

const onGridReady = async (params) => {
  gridApi = params.api;
  warehouses.value = await fetchProductWarehouses(route.params.id);
  gridApi.setGridOption("rowData", warehouses.value);
};

const colDefs = ref([
  {
    field: "warehouse.nickname",
    headerName: "Warehouse",
    cellDataType: "text",
  },
  {
    field: "quantities.available",
    headerName: "Available",
    cellDataType: "text",
  },
  {
    field: "quantities.on_hand",
    headerName: "On Hand",
    cellDataType: "text",
  },
  {
    field: "quantities.allocated",
    headerName: "Picked",
    cellDataType: "text",
  },
  {
    field: "quantities.reserved",
    headerName: "Reserved",
    cellDataType: "text",
  },
  {
    field: "quantities.inbound",
    headerName: "Inbound",
    cellDataType: "text",
  },
  {
    field: "quantities.damaged",
    headerName: "Damaged",
    cellDataType: "text",
  },
]);

</script>

<template>
  <ProductLayout :product="product">
    <div class="w-100">
      <div v-if="product" class="px-4">
        <div class="w-100">
          <div class="w-auto px-2">
            <ProductHeader :productId="product.id" class="" />
          </div>


          <div class="mx-2 mb-6 mt-8">
            <v-row>
              <v-col cols="12" sm="12">
                <v-card class="pa-2">
                  <v-card-title class="bg-surface-light">Product Details</v-card-title>
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>SKU:</strong> {{ product.sku }}
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Description:</strong> {{ product.name }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Nickname:</strong> {{ product.nickname }}
                        </v-sheet>
                      </v-col>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>UPC:</strong> {{ product.upc }}
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </div>


          <v-card class="mt-2 pa-2 w-auto" color="transparent" elevation="0">
            <div class="warehouse-table d-flex">
              <AgGridVue style="width: 100%; height: 100%" :class="themeStore.agTheme" :columnDefs="colDefs"
                @grid-ready="onGridReady"></AgGridVue>
            </div>
          </v-card>


          <div class="mx-2 mb-6 mt-8">
            <v-row>
              <v-col cols="6" sm="6">
                <v-card class="pa-2">
                  <v-card-title class="bg-surface-light">Weights & Dims</v-card-title>
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>WT(lb)</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.weight }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Cubic(ft)</strong>
                        </v-sheet>
                      </v-col>

                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.cubic_feet || "-" }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>LxWxH (in)</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.length }} x {{ product.width }} x {{ product.height }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>UOM</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2" v-if="!product.units1_per_units2">
                          {{ product.units_per_units1 }} {{ product.units }} per {{ product.units1 }}
                        </v-sheet>
                        <v-sheet class="pa-2" v-if="product.units1_per_units2">
                          {{ product.units_per_units1 }} {{ product.units }} per {{ product.units1 }} {{ product.units1_per_units2 }} {{ product.units1 }} per {{ product.units2 }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Lowest Selling Unit</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.lowest_selling_unit}}
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="6" sm="6">
                <v-card class="pa-2">
                  <v-card-title class="bg-surface-light">Alcohol</v-card-title>
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Is Alcohol?</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.is_alcohol === true ? 'Yes':'No' }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Size(L)</strong>
                        </v-sheet>
                      </v-col>

                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.liters_per || "-" }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>Vintage</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{product.vintage }}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>PLCB Code</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.plcb_code}}
                        </v-sheet>
                      </v-col>

                      <v-responsive width="100%"></v-responsive>

                      <v-col>
                        <v-sheet class="pa-2">
                          <strong>SCC</strong>
                        </v-sheet>
                      </v-col>
                      <v-col>
                        <v-sheet class="pa-2">
                          {{ product.scc}}
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </div>

          
        </div>
      </div>
    </div>
  </ProductLayout>
</template>

<style>
.warehouse-table {
  height: 563px;
}

@media (max-width: 768px) {
  .details-weights {
    flex-direction: column;
  }

  .details {
    min-width: 75%;
  }

  .weightsanddims {
    min-width: 75%;
  }
}
</style>
