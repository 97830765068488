<script setup>
import { useRouter } from "vue-router";

const router = useRouter()

const props = defineProps({
    productId: {
        type: Number,
        required: true,
    }
});

const handleEditAction = () => {
    router.push(`/products/${props.productId}/edit`)
}

</script>

<template>
    <v-card class="pa-2">
        <v-card-title>
            <div class="d-flex justify-space-between">
                Product # - {{
                    productId
                }}
                <v-btn color="primary" @click="handleEditAction">Edit</v-btn>
            </div>
        </v-card-title>
    </v-card>
</template>
